import type AppStore from '@src/app/AppStore'
import {
  PREMIUM_PLAN_ANNUAL_PRICE_ID,
  PREMIUM_PLAN_ANNUAL_V6_PRICE_ID,
  PREMIUM_PLAN_MONTHLY_PRICE_ID,
  PREMIUM_PLAN_MONTHLY_V6_PRICE_ID,
  SCALE_PLAN_ANNUAL_V6_PRICE_ID,
  SCALE_PLAN_MONTHLY_V6_PRICE_ID,
  STANDARD_PLAN_ANNUAL_PRICE_ID,
  STANDARD_PLAN_ANNUAL_V5_PRICE_ID,
  STANDARD_PLAN_ANNUAL_V6_PRICE_ID,
  STANDARD_PLAN_MONTHLY_PRICE_ID,
  STANDARD_PLAN_MONTHLY_V3_PRICE_ID,
  STANDARD_PLAN_MONTHLY_V5_PRICE_ID,
  STANDARD_PLAN_MONTHLY_V6_PRICE_ID,
  type PriceId,
} from '@src/app/billing/pricing/constants'
import {
  isA2PCharityAnnual,
  isA2PCharityMonthly,
  isA2PLowVolumeStandardAnnual,
  isA2PLowVolumeStandardMonthly,
  isA2PSolePropAnnual,
  isA2PSolePropMonthly,
} from '@src/app/settings/billing/utils/isA2P'
import {
  isAddditionalNumberAnnual,
  isAddditionalNumberMonthly,
} from '@src/app/settings/billing/utils/isAdditionalNumber'
import {
  isPlanPremiumAnnual,
  isPlanPremiumMonthly,
  isPlanScaleAnnual,
  isPlanScaleMonthly,
  isPlanStandardAnnual,
  isPlanStandardMonthly,
} from '@src/app/settings/billing/utils/isPlan'
import match from '@src/lib/match'

export type Plan = 'standard' | 'premium' | 'scale'
export type PlanNames = Record<Plan, string>

export type Cycle = 'monthly' | 'annually'
export type Version = 2 | 3 | 5 | 6

export type Pricing = {
  standard: Record<Cycle, number>
  premium: Record<Cycle, number>
  scale?: Record<Cycle, number>
}

export const price: Record<Version, Pricing> = {
  2: {
    standard: {
      monthly: 10,
      annually: 10,
    },
    premium: {
      monthly: 25,
      annually: 20,
    },
  },
  3: {
    standard: {
      monthly: 13,
      annually: 10,
    },
    premium: {
      monthly: 25,
      annually: 20,
    },
  },
  5: {
    standard: {
      monthly: 17,
      annually: 13,
    },
    premium: {
      monthly: 25,
      annually: 20,
    },
  },
  6: {
    standard: {
      monthly: 19,
      annually: 15,
    },
    premium: {
      monthly: 33,
      annually: 23,
    },
    scale: {
      monthly: 47,
      annually: 35,
    },
  },
}

const percentageDiscount = {
  standard: {
    monthly: 0,
    annually: 21,
  },
  premium: {
    monthly: 0,
    annually: 30,
  },
  scale: {
    monthly: 0,
    annually: 30,
  },
} satisfies Record<Plan, Record<Cycle, number>>

const savings = {
  standard: {
    monthly: 0,
    annually: 48,
  },
  premium: {
    monthly: 0,
    annually: 120,
  },
  scale: {
    monthly: 0,
    annually: 144,
  },
}

type PlanIdRecord = {
  standard: {
    monthly: Record<Version, PriceId>
    annually: Record<Version, PriceId>
  }
  premium: {
    monthly: Record<Version, PriceId>
    annually: Record<Version, PriceId>
  }
  scale: {
    monthly: Partial<Record<Version, PriceId>>
    annually: Partial<Record<Version, PriceId>>
  }
}

type VersionPriceMap = Record<Version, PriceId>
type ScaleVersionPriceMap = Partial<Record<Version, PriceId>>

export const planIds = {
  standard: {
    monthly: {
      2: STANDARD_PLAN_MONTHLY_PRICE_ID,
      3: STANDARD_PLAN_MONTHLY_V3_PRICE_ID,
      5: STANDARD_PLAN_MONTHLY_V5_PRICE_ID,
      6: STANDARD_PLAN_MONTHLY_V6_PRICE_ID,
    } satisfies VersionPriceMap,
    annually: {
      2: STANDARD_PLAN_ANNUAL_PRICE_ID,
      3: STANDARD_PLAN_ANNUAL_PRICE_ID,
      5: STANDARD_PLAN_ANNUAL_V5_PRICE_ID,
      6: STANDARD_PLAN_ANNUAL_V6_PRICE_ID,
    } satisfies VersionPriceMap,
  },
  premium: {
    monthly: {
      2: PREMIUM_PLAN_MONTHLY_PRICE_ID,
      3: PREMIUM_PLAN_MONTHLY_PRICE_ID,
      5: PREMIUM_PLAN_MONTHLY_PRICE_ID,
      6: PREMIUM_PLAN_MONTHLY_V6_PRICE_ID,
    } satisfies VersionPriceMap,
    annually: {
      2: PREMIUM_PLAN_ANNUAL_PRICE_ID,
      3: PREMIUM_PLAN_ANNUAL_PRICE_ID,
      5: PREMIUM_PLAN_ANNUAL_PRICE_ID,
      6: PREMIUM_PLAN_ANNUAL_V6_PRICE_ID,
    } satisfies VersionPriceMap,
  },
  scale: {
    monthly: {
      6: SCALE_PLAN_MONTHLY_V6_PRICE_ID,
    } satisfies ScaleVersionPriceMap,
    annually: {
      6: SCALE_PLAN_ANNUAL_V6_PRICE_ID,
    } satisfies ScaleVersionPriceMap,
  },
} satisfies PlanIdRecord

type PricePattern = [predicate: (item: PriceId) => boolean, value: number]

export default class PricingController {
  constructor(private app: AppStore) {}

  get subscription() {
    return this.app.service.billing.getCurrentSubscription()
  }

  get isSubscriptionLegacy() {
    const version = this.subscription.version ?? 2

    return version < this.lastVersion
  }

  get subscriptionPlan(): Plan | undefined {
    switch (this.subscription.type) {
      case 'premium':
        return 'premium'
      case 'standard':
        return 'standard'
      case 'scale':
        return 'scale'
      default:
        return undefined
    }
  }

  get lastVersion(): Version {
    return 6
  }

  /**
   * Maps any number to their matching pricing version
   */
  getMappedVersion(versionNumber: number): Version {
    // Why no version 4?
    // Version 4 was a quickly rejected attempt at a new pricing version
    // It was never used and we should not support it

    if (versionNumber === 3) {
      return 3
    }

    if (versionNumber === 6) {
      return 6
    }

    if (versionNumber > 3) {
      return 5
    }

    return 2
  }

  getVersionPrice(version: Version) {
    return price[this.getMappedVersion(version)]
  }

  getPlanPrice(version: Version, plan: Plan): Record<Cycle, number> | undefined {
    const pricing = this.getVersionPrice(version)
    return pricing[plan]
  }

  getPrice({
    version,
    plan,
    cycle,
  }: {
    version: Version
    plan: Plan
    cycle: Cycle
  }): number {
    return this.getPlanPrice(version, plan)?.[cycle] ?? 0
  }

  getPriceForCurrentSubscription({ plan, cycle }: { plan: Plan; cycle: Cycle }) {
    return this.getPrice({
      version: this.subscription.version as Version,
      plan,
      cycle,
    })
  }

  /**
   * Gets the price of the given price id that matches our known set of price ids.
   *
   * @param version - The version can be specified to get the price for specific versions
   * @param cycle - The cycle is used to get the price of the two different cycles regardless of the price id
   */
  getPriceById({
    priceId,
    version = this.lastVersion,
  }: {
    priceId: PriceId
    version?: Version
  }) {
    const patterns: PricePattern[] = [
      [
        isPlanStandardMonthly,
        this.getPrice({ version, plan: 'standard', cycle: 'monthly' }),
      ],
      [
        isPlanStandardAnnual,
        this.getPrice({ version, plan: 'standard', cycle: 'annually' }) * 12,
      ],
      [
        isPlanPremiumMonthly,
        this.getPrice({ version, plan: 'premium', cycle: 'monthly' }),
      ],
      [
        isPlanPremiumAnnual,
        this.getPrice({ version, plan: 'premium', cycle: 'annually' }) * 12,
      ],
      [isPlanScaleMonthly, this.getPrice({ version, plan: 'scale', cycle: 'monthly' })],
      [
        isPlanScaleAnnual,
        this.getPrice({ version, plan: 'scale', cycle: 'annually' }) * 12,
      ],
      [isAddditionalNumberMonthly, 5],
      [isAddditionalNumberAnnual, 5 * 12],
      [isA2PLowVolumeStandardMonthly, 1.5],
      [isA2PLowVolumeStandardAnnual, 1.5 * 12],
      [isA2PSolePropMonthly, 2],
      [isA2PSolePropAnnual, 2 * 12],
      [isA2PCharityMonthly, 3],
      [isA2PCharityAnnual, 3 * 12],
    ]

    return match(priceId)(patterns) ?? 0
  }

  getPlanId({
    plan,
    cycle,
    version,
  }: {
    plan: Plan
    cycle: Cycle
    version: Version
  }): PriceId {
    const planCyclePrices = planIds[plan][cycle]
    return (planCyclePrices as Record<Version, PriceId>)[version]
  }

  getDiscount({ plan, cycle }: { plan: Plan; cycle: Cycle }) {
    return percentageDiscount[plan][cycle]
  }

  getSavings({ plan, cycle }: { plan: Plan; cycle: Cycle }) {
    return savings[plan][cycle]
  }

  isPlanLegacy({ version, plan, cycle }: { version: Version; plan: Plan; cycle: Cycle }) {
    if (!this.isSubscriptionLegacy) {
      return false
    }

    const hasLatestPrice =
      this.getPrice({ version: this.lastVersion, plan, cycle }) ===
      this.getPrice({ version, plan, cycle })

    const isSamePlan = this.subscriptionPlan === plan

    return isSamePlan && !hasLatestPrice
  }

  getTotalAmount({
    version,
    plan,
    cycle,
  }: {
    version: Version
    plan: Plan
    cycle: Cycle
  }) {
    const price = this.getPrice({ version, plan, cycle })
    return cycle === 'annually' ? price * 12 : price
  }
}
